import React from 'react'

import Intro from '@components/ui/Intro'
import Root from '@layouts/Root'
import Seo from '@components/utils/Seo'

const NotFoundPage = () => (
  <Root>
    <Seo title="404 - Not Found" />

    <section
      className="wrapper-l"
      style={{ marginTop: '160px', marginBottom: '160px' }}
    >
      <Intro pageTitle="Page Not Found">
        <p>Soon&trade;</p>
      </Intro>
    </section>
  </Root>
)

export default NotFoundPage
